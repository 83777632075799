<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>{{ title }}</v-card-title>
         <v-card-text>
            <form>
               <v-row>
                  <v-col>
                     <v-text-field
                        v-model="nombre"
                        label="Nombre"
                        :error-messages="nombreErrors"
                        required
                        @input="$v.nombre.$touch()"
                        @blur="$v.nombre.$touch()"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                     <v-text-field
                        v-model="num"
                        label="#"
                        type="number"
                        class="inputnum"
                        :error-messages="numErrors"
                        required
                        @input="$v.num.$touch()"
                        @blur="$v.num.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col>
                     <v-select
                        v-model="categoria"
                        label="Categoria"
                        :items="categorias"
                        item-text="text"
                        item-value="value"
                        required
                        :error-messages="categoriaErrors"
                        @input="$v.categoria.$touch()"
                        @blur="$v.categoria.$touch()"
                     ></v-select>
                  </v-col>
                  <v-col>
                     <v-select
                        v-model="rango"
                        label="Rango"
                        :items="rangos"
                        item-text="text"
                        item-value="value"
                        required
                        :error-messages="rangoErrors"
                        @input="$v.rango.$touch()"
                        @blur="$v.rango.$touch()"
                     ></v-select>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col>
                     <v-combobox
                        v-model="gym"
                        label="Gym"
                        required
                        :items="gyms"
                        :error-messages="gymErrors"
                        @input="$v.gym.$touch()"
                        @blur="$v.gym.$touch()"
                     ></v-combobox>
                  </v-col>
                  <v-col>
                     <v-text-field
                        v-model="telefono"
                        label="Telefono"
                        :error-messages="telefonoErrors"
                        required
                        @input="$v.telefono.$touch()"
                        @blur="$v.telefono.$touch()"
                     ></v-text-field>
                  </v-col>
               </v-row>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength, integer } from "vuelidate/lib/validators";

export default {
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
      },
      num: {
         required,
         integer
      },
      categoria: {
         required,
      },
      rango: {
         required,
      },
      gym: {
         required,
         maxLength: maxLength(30),
      },
      telefono: {
         maxLength: maxLength(10),
         minLength: minLength(10),
         integer,
      },
   },
   data: function () {
      return {
         atleta_id: null,
         nombre: "",
         num: null,
         categoria: null,
         rango: null,
         gym: "",
         telefono: "",
         dialog: false,
         isloading: false,
         gyms: [],
         categorias: [
            {
               value: "F",
               text: "Femenino",
            },
            {
               value: "V",
               text: "Varonil",
            },
         ],
         rangos: [
            {
               value: "P",
               text: "Principiante",
            },
            {
               value: "I",
               text: "Intermedio",
            },
            {
               value: "M",
               text: "Master",
            },
            {
               value: "A",
               text: "Avanzado",
            },
            {
               value: "R",
               text: "RX",
            },
            {
               value: "K",
               text: "Kids",
            },
         ],
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertAtleta", {
                     nombre: this.nombre,
                     num: this.num,
                     categoria: this.categoria,
                     rango: this.rango,
                     gym: this.gym,
                     telefono: this.telefono,
                  });
               } else {
                  res = await this.$store.dispatch("updateAtleta", {
                     atleta_id: this.atleta_id,
                     nombre: this.nombre,
                     num: this.num,
                     categoria: this.categoria,
                     rango: this.rango,
                     gym: this.gym,
                     telefono: this.telefono,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      Show: function (i) {
         this.dialog = true;
         this.atleta_id = i.atleta_id;
         this.nombre = i.nombre;
         this.num = i.num;
         this.categoria = i.categoria;
         this.rango = i.rango;
         this.gym = i.gym;
         this.telefono = i.telefono;

         this.gyms = [];
         this.$store.state.atletas.forEach((element) => {
            this.gyms.push(element.gym);
         });
      },
   },
   computed: {
      isEdit() {
         if (this.atleta_id) {
            return true;
         } else {
            return false;
         }
      },
      title() {
         if (this.atleta_id) {
            return "Editar atleta";
         } else {
            return "Crear atleta";
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      numErrors() {
         const errors = [];
         if (!this.$v.num.$dirty) return errors;
         !this.$v.num.required && errors.push("El numero de atleta es requerido");
         !this.$v.num.integer && errors.push("Debe ser un numero");
         return errors;
      },
      categoriaErrors() {
         const errors = [];
         if (!this.$v.categoria.$dirty) return errors;
         !this.$v.categoria.required && errors.push("Es requerido");
         return errors;
      },
      rangoErrors() {
         const errors = [];
         if (!this.$v.rango.$dirty) return errors;
         !this.$v.rango.required && errors.push("El rango es requerido");
         return errors;
      },
      gymErrors() {
         const errors = [];
         if (!this.$v.gym.$dirty) return errors;
         !this.$v.gym.required && errors.push("El gym es requerido");
         !this.$v.gym.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      telefonoErrors() {
         const errors = [];
         if (!this.$v.telefono.$dirty) return errors;
         !this.$v.telefono.minLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.maxLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.integer && errors.push("Debe contener caracteres numericos");
         return errors;
      },
   },
};
</script>
